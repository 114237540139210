<template>
  <div class="about">
    LOGOUT
  </div>
</template>
<script>
// import axios from 'axios';

import { logout } from '@/variables'

export default {
  name: 'logout',
  components : {
    
  },
  data () { 
    return {
     
      
      }
    },
  created()
  {
    this.doLogout();
  }, 
    computed: {
   
   },
  watch:
  {
    
  },
  methods: {
     doLogout()
     {
         //alert('logout')
        logout();
         //alert('emit')
        this.$emit('logout');
        //alert('router')
        let ident = sessionStorage.ident;
        sessionStorage.clear();
        this.$router.replace("/home/"+ident);
     }
       
    }
}
</script>
<style lang="scss" scoped>

</style>